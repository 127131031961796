import { createRouteRef } from '@backstage/core-plugin-api';
export const rootRouteRef = createRouteRef({
  id: 'jb-jira',
});

export const sprintReportRouteRef = createRouteRef({
  id: 'sprint-report',
});

export const qualityRouteRef = createRouteRef({
  id: 'quality',
});

export const releasesRouteRef = createRouteRef({
  id: 'releases',
});

export const pipelinesRouteRef = createRouteRef({
  id: 'pipelines',
});

export const leaderBoardRouteRef = createRouteRef({
  id: 'leader-board',
});
export const capacityRouteRef = createRouteRef({
  id: 'capacity',
});
export const calendarRouteRef = createRouteRef({
  id: 'calendar',
});
export const itsoRouteRef = createRouteRef({
  id: 'riskcockpit',
});
export const appRouteRef = createRouteRef({
  id: 'apps',
});
export const squadStructureRouteRef = createRouteRef({
  id: 'squads',
});
export const itsoAdminRouteRef = createRouteRef({
  id: 'itso-admin',
});

export const engageJourneyRouteRef = createRouteRef({
  id: 'engage-journey',
});

export const rocPlanRouteRef = createRouteRef({
  id: 'roc-plan',
});
export const onboardingRouteRef = createRouteRef({
  id: 'onboarding',
});
export const piRouteRef = createRouteRef({
  id: 'pi-planning',
});
export const testingRouteRef = createRouteRef({
  id: 'testing',
});
export const certificateRouteRef = createRouteRef({
  id: 'certificates',
});
export const aboutRouteRef = createRouteRef({
  id: 'about',
});
export const vulnerabilitiesRouteRef = createRouteRef({
  id: "vulnerabilities"
})
export const rFCComponent = createRouteRef({
  id: "rfc"
})
export const genAIRouteRef = createRouteRef({
    id: 'genAI',
});
export const g3CertMigrationRouteRef = createRouteRef({
    id: 'g3CertMigration',
});